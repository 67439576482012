<template>
  <b-card>
    <error-alert
      v-if="errors.code !== 400"
      :errors="errors"
      message="Виникла помилка при пошуку операцій!"
    />
    <operation-list-filter
      ref="operationListFilter"
      :is-submitting="isSubmitting"
      :is-submitting-calculate="isSubmittingCalculate"
      :per-page="perPage"
      :current-page="currentPage"
      :total-items="total"
      :total-sum="totalSum"
      :prop-processed-at-from="filter.processedAtFrom.value"
      :prop-processed-at-to="filter.processedAtTo.value"
      :prop-paysystem-operation-id="filter.paysystemOperationId.value"
      :prop-received-at-from="filter.receivedAtFrom.value"
      :prop-received-at-to="filter.receivedAtTo.value"
      :prop-id="filter.id.value"
      :prop-financial-model-id="filter.financialModelId.value"
      :prop-nbu-paysystem-code="filter.nbuPaysystemCode.value"
      :prop-operation-type="filter.operationType.value"
      :prop-operation-destination-code="filter.operationDestinationCode.value"
      :prop-payer="filter.payer.value"
      :prop-payer-card-hash="filter.payerCardHash.value"
      :prop-payer-contract-number="filter.payerContractNumber.value"
      :prop-payee="filter.payee.value"
      :prop-payee-card-hash="filter.payeeCardHash.value"
      :prop-payee-contract-number="filter.payeeContractNumber.value"
      :prop-terminal-name="filter.terminalName.value"
      :prop-amount-from="filter.amountFrom.value"
      :prop-amount-to="filter.amountTo.value"
      :prop-created-at-from="filter.createdAtFrom.value"
      :prop-created-at-to="filter.createdAtTo.value"
      :prop-currency="filter.currency.value"
      :prop-country="filter.country.value"
      :prop-payer-counterpart-id="filter.payerCounterpartId.value"
      :prop-payee-counterpart-id="filter.payeeCounterpartId.value"
      :prop-payer-card-mask="filter.payerCardMask.value"
      :prop-payee-card-mask="filter.payeeCardMask.value"
      :prop-payee-is-public="filter.payeeIsPublic.value"
      :prop-payer-is-public="filter.payerIsPublic.value"
      :prop-errors="errors"
      v-on:filter-change="changeFilter"
      v-on:filter-default="setDefaultFilter"
      v-on:filter-clear="setClearFilter"
      v-on:total-sum="getTotalSum"
    />
    <operation-list-table :items="operationList" :submitting="isSubmitting" />
    <operation-list-table-nav
      :prop-current-page="currentPage"
      :prop-per-page="perPage"
      :total="total"
      v-on:current-page-change="changeCurrentPage"
      v-on:per-page-change="changePerPage"
    />
    <operation-export-modal :prop-filters="prepareFilterParams()" />
  </b-card>
</template>

<script>
import ErrorAlert from "@/components/ErrorAlert.vue";
import OperationListFilter from "@/entity/Operations/components/OperationListFilter.vue";
import OperationListTable from "@/entity/Operations/components/OperationListTable.vue";
import OperationListTableNav from "@/entity/Operations/components/OperationListTableNav.vue";
import OperationExportModal from "@/entity/Operations/components/OperationExportModal.vue";
import api from "@/api/api";
import moment from "moment/moment";
import queryFilter from "@/shared/filterQuery";
import qs from "qs";
import debounce from "debounce";
import { mapGetters } from "vuex";

export default {
  name: "OperationList",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$auth.can(vm.$stringConstants("PERMISSION_VIEW_OPERATION"))) {
        next();
      } else {
        next({ name: "Page404" });
      }
    });
  },
  computed: {
    ...mapGetters({
      me: "auth/me",
    }),
  },
  components: {
    ErrorAlert,
    OperationExportModal,
    OperationListFilter,
    OperationListTable,
    OperationListTableNav,
  },
  created() {
    this.debouncedGetOperationList = debounce(this.getOperationList, 500);
  },
  data: () => {
    return {
      isSubmitting: false,
      isSubmittingCalculate: false,
      filter: {},
      currentPage: 1,
      perPage: 50,
      total: 0,
      totalSum: [],
      operationList: [],
      errors: {},
      requestCount: 0,
    };
  },
  methods: {
    prepareFilterParams: function () {
      const filters = {};
      Object.entries(this.filter).forEach(([, filter]) => {
        if (
          filter.value !== null &&
          filter.value !== "" &&
          filter.value.length !== 0
        ) {
          filters[`filter[${filter.name}@${filter.type}]`] = filter.value;
        }
      });
      return {
        page: this.currentPage,
        per_page: this.perPage,
        ...filters,
      };
    },
    async getOperationList() {
      if (
        this.$refs.operationListFilter &&
        !(await this.$refs.operationListFilter.validate())
      ) {
        return;
      }

      this.errors = {};
      this.operationList = [];
      this.totalSum = [];
      this.isSubmitting = true;
      this.requestCount += 1;
      api
        .getOperationList(this.prepareFilterParams())
        .then(({ data }) => {
          this.operationList = data.data;
          this.currentPage = data.meta.current_page;
          this.perPage = data.meta.per_page;
          this.total = data.meta.total;
        })
        .catch((error) => {
          if (error.response !== undefined) {
            this.errors = {
              code: error.response.status,
              description:
                error.response.status === 400
                  ? error.response.data.description
                  : error.response.statusText,
            };
          }
        })
        .finally(() => {
          --this.requestCount;
          if (this.requestCount === 0) {
            this.isSubmitting = false;
          }
        });
    },
    changeCurrentPage: function (v = 1) {
      this.$set(this, "currentPage", v);
      this.debouncedGetOperationList();
    },
    changePerPage: function (v) {
      this.$set(this, "perPage", v);
      this.changeCurrentPage();
    },
    changeFilter: function (v) {
      let [name, value] = v;
      name = name.replace("prop", "");
      name = name[0].toLowerCase() + name.slice(1);
      this.$set(this.filter[name], "value", value);
      this.changeCurrentPage();
    },
    getDefaultFilter: function () {
      return {
        processedAtFrom: {
          name: "processed_at",
          type: "gte",
          date: true,
          value:
            this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")
              ? moment("2024-08-30").startOf("day").toDate()
              : moment().startOf("day").toDate(),
        },
        processedAtTo: {
          name: "processed_at",
          type: "lte",
          date: true,
          value:
            this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")
              ? moment("2024-08-30").endOf("day").toDate()
              : moment().toDate(),
        },
        paysystemOperationId: {
          name: "paysystem_operation_id",
          type: "where",
          value: "",
        },
        receivedAtFrom: {
          name: "received_at",
          type: "gte",
          date: true,
          value: null,
        },
        receivedAtTo: {
          name: "received_at",
          type: "lte",
          date: true,
          value: null,
        },
        id: {
          name: "id",
          type: "where",
          value: "",
        },
        financialModelId: {
          name: "financial_model_id",
          type: "where",
          value: "",
        },
        nbuPaysystemCode: {
          name: "nbu_paysystem_code",
          type: "where",
          value: "",
        },
        operationType: {
          name: "operation_type_id",
          type: "whereIn",
          value: [],
        },
        operationDestinationCode: {
          name: "operation_destination_code_id",
          type: "whereIn",
          value: [],
        },
        payer: {
          name: "payer",
          type: "ilike",
          value: "",
        },
        payerCardHash: {
          name: "payer_card_hash",
          type: "where",
          value: "",
        },
        payerContractNumber: {
          name: "payer_contract_number",
          type: "where",
          value: "",
        },
        payee: {
          name: "payee",
          type: "ilike",
          value: "",
        },
        payeeCardHash: {
          name: "payee_card_hash",
          type: "where",
          value: "",
        },
        payeeContractNumber: {
          name: "payee_contract_number",
          type: "where",
          value: "",
        },
        terminalName: {
          name: "terminal_name",
          type: "ilike",
          value: "",
        },
        amountFrom: {
          name: "amount",
          type: "gte",
          value: "",
        },
        amountTo: {
          name: "amount",
          type: "lte",
          value: "",
        },
        createdAtFrom: {
          name: "created_at",
          type: "gte",
          date: true,
          value: null,
        },
        createdAtTo: {
          name: "created_at",
          type: "lte",
          date: true,
          value: null,
        },
        currency: {
          name: "currency",
          type: "where",
          value: "",
        },
        country: {
          name: "country",
          type: "where",
          value: "",
        },
        payerCounterpartId: {
          name: "payer_counterpart_id",
          type: "whereIn",
          value: [],
        },
        payeeCounterpartId: {
          name: "payee_counterpart_id",
          type: "whereIn",
          value: [],
        },
        payerCardMask: {
          name: "payer",
          type: "where",
          value: "",
        },
        payeeCardMask: {
          name: "payee",
          type: "where",
          value: "",
        },
        payeeIsPublic: {
          name: "payee_is_public",
          type: "where",
          value: "",
        },
        payerIsPublic: {
          name: "payer_is_public",
          type: "where",
          value: "",
        },
      };
    },
    setDefaultFilter: function () {
      const query = Object.assign({}, this.$route.query);
      if (this.me.role.name === this.$stringConstants("ROLE_INSPECTOR")) {
        query["processedAtFrom"] = moment("2024-08-30").startOf("day").toDate();
        query["processedAtTo"] = moment("2024-08-30").endOf("day").toDate();
      }
      const newFilter = queryFilter.methods.getFilterFromQuery(
        query,
        this.getDefaultFilter()
      );
      this.$set(this, "filter", newFilter);
      this.changeCurrentPage();
    },
    setClearFilter: function () {
      this.$set(this, "filter", this.getDefaultFilter());
      this.changeCurrentPage();
    },
    getTotalSum: function () {
      this.isSubmittingCalculate = true;
      api
        .getTotalSumByOperations(this.prepareFilterParams())
        .then(({ data }) => {
          this.totalSum = data.totalSum;
          this.isSubmittingCalculate = false;
        });
    },
  },
  beforeMount() {
    this.setDefaultFilter();
  },
  watch: {
    filter: {
      deep: true,
      handler(filter) {
        const newQuery = queryFilter.methods.getQueryFromFilter({}, filter);
        if (
          qs.stringify(this.$route.query) !==
          qs.stringify(newQuery, { arrayFormat: "comma" })
        )
          this.$router.replace({ query: newQuery });
      },
    },
  },
};
</script>
